import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => ( {
	inputRoot: {
		backgroundColor: theme.other.darkMode ?
			theme.other.paletteNew.darkModeDarkGrey :
			theme.other.paletteNew.secondaryBackgroundGrey,
		width        : "100%",
		borderRadius : "10px",
		borderWidth  : "0",
		color        : theme.other.colors.text.primary,
		// height        : "4rem",
		padding      : "2rem",
		justifySelf  : "center",

		[rooterMediaQuery.mobileAndTablet]: {
			width: "100%"
		}

	},
	crossIcon: {
		background: "none",

		":hover": {
			background: "none"
		}
	}
} ) );
