import Presentation from "./presentation.jsx";

const component = props => {

	return (
		<Presentation
			{ ...props }
		/>
	);
};

export default component;
