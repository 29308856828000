import { ActionIcon, Input } from "@mantine/core";

import Icon from "_components/atoms/icon";

import useStyles from "./styles";

const component = ( {
	searchTerm,
	setSearchTerm,
	onFocus,
	onPressEnter,
	onPressEsc,
	placeholder = "Search",
	inputRef
} ) => {
	const { classes } = useStyles ( undefined, { name: "searchTextField" } );

	function onClickCrossIcon () {
		setSearchTerm ( "" );
		inputRef?.current?.focus ();
	}

	return (
		<form
			style = { { flex: "1" } }
		>
			<Input
				ref                       = { inputRef }
				autoComplete              = "off"
				classNames                = { {
					input: classes.inputRoot
				} }
				id                        = "input-popover-target"
				onChange                  = { e => setSearchTerm ( e.target.value ) }
				onFocus                   = { onFocus }
				onKeyDown                 = { e => {
					if ( e.code === "Escape" ) {
						onPressEsc ();
					}
					if ( e.code === "Enter" ) {
						onPressEnter ();
					}
				} }
				placeholder               = { placeholder }
				rightSection              = { (
					<RightIcon
						inputRef         = { inputRef }
						onClickCrossIcon = { onClickCrossIcon }
						searchTerm       = { searchTerm }
						setSearchTerm    = { setSearchTerm }
					/>
				) }
				rightSectionPointerEvents = "all"
				rightSectionWidth         = { 70 }
				value                     = { searchTerm }
			/>
		</form>
	);
};

function RightIcon ( { searchTerm, onClickCrossIcon } ) {
	const { classes } = useStyles ();

	if ( searchTerm.length > 0 )
		return (
			<ActionIcon className = { classes.crossIcon }>
				<Icon
					name    = "cross"
					onClick = { onClickCrossIcon }
					size    = { 24 }
				/>
			</ActionIcon>
		);

	return (
		<Icon sameInBothTheme
			name = "iconSearch"
			size = { 24 }
		/>
	);

}
export default component;
