import Icon       from "_components/atoms/icon";
import Typography from "_components/atoms/typography";

import useStyles from "./styles.js";

// to find the last matching index of searchterm and predictive search
const findIndex = ( a, b ) => {
	for ( let i = 0, l = Math.max ( a.length, b.length ); i < l; i ++ ) {
		if ( a[i] != b[i] )
			return i;
	}

	return Math.max ( a.length, b.length );
};

const component = ( { predictiveSearches, recentSearches, searchTerm, handleSearchRoute } ) => {

	const { classes }          = useStyles ( );
	let validateRecentSearches = recentSearches?.some ( search => search?.term.toLowerCase ().includes ( searchTerm.toLowerCase () ) );

	if ( !predictiveSearches?.length && !validateRecentSearches )
		return (
			<div className = { classes.predictiveSearchContainer }>
				<div
					className = { classes.searchContainer }
					onClick   = { () => handleSearchRoute ( searchTerm ) }
				>
					<Icon
						className = { classes.searchIcon }
						name      = "search"
					/>

					<span className = { classes.textContainer }>
						{searchTerm}

					</span>

				</div>
			</div>
		);

	return (

		<div className = { classes.predictiveSearchContainer }>
			{recentSearches?.map ( ( search, idx ) => {

				// logic to show recent searches which matches searchTerm
				if ( !search?.term.toLowerCase ().includes ( searchTerm.toLowerCase () ) ) return;

				return (
					<div key   = { idx }
						className = { classes.searchContainer }
						onClick   = { () => handleSearchRoute ( search?.term ) }
					>
						<div className = { classes.searchTermContainer }>
							<Icon
								className = { classes.searchIcon }
								name      = "watch"
							/>

							<Typography className = { classes.textContainer }
								title                = { search?.term }
							/>
						</div>

						<Typography
							className = { classes.searchedText }
							title     = { "Recently Searched" }
						/>
					</div>
				);
			} )}

			{predictiveSearches?.map ( ( search, idx ) => {

				let index          = findIndex ( search?.term, searchTerm );
				let typedText      = search?.term.substring ( 0, index );
				let suggestiveText = search?.term.substring ( index );

				return (
					<div key   = { idx }
						className = { classes.searchContainer }
						onClick   = { () => handleSearchRoute ( search?.term ) }
					>
						<Icon
							className = { classes.searchIcon }
							name      = "search"
						/>

						<span className = { classes.textContainer }>
							<b>
								{typedText}
							</b>

							{suggestiveText}
						</span>

					</div>
				);
			} )}
		</div>
	);

};

export default component;
