import XHR         from "_common/xhr";
import { configs } from "_config/index";

export const getRecentSearchesAndTrendingQueries = async userId => {
	let url = "";

	if ( userId ) {
		url = `${ configs.SEARCH_URL }search/trending?pageSize=10&userId=${ userId }`;
	} else {
		url = `${ configs.SEARCH_URL }search/trending?pageSize=10`;

	}
	const result = await XHR.get ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return result;
};

export const getPredictiveSearches = async debouncedSearchTerm => {
	const result = await XHR.get ( {
		url     : `${ configs.SEARCH_URL }search/suggestions?query=${ encodeURIComponent ( debouncedSearchTerm ) }`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return result;
};

export const clearRecentSearchesApi = async userId => {

	const result = await XHR.post ( {
		url     : `${ configs.SEARCH_URL }search/clearRecentSearch?userId=${ userId }`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return result;
};