import InitialSearchData  from "./initialSearchData";
import PredictiveSearches from "./predictiveResults";
import useStyles          from "./styles.js";

const component = ( {
	searchTerm,
	trendingResults,
	predictiveSearches,
	recentSearches,
	handleSearchRoute,
	isSearching
} ) => {

	const { classes } = useStyles ( );

	// renders when we have typed 3 characters in search input
	if ( searchTerm?.length > 2 && !isSearching ) return (
		<PredictiveSearches
			handleSearchRoute  = { handleSearchRoute }
			predictiveSearches = { predictiveSearches }
			recentSearches     = { recentSearches }
			searchTerm         = { searchTerm }
		/>
	);

	// renders when search input is focussed
	return (
		<div className = { classes.initialResultsWrapper }>
			<InitialSearchData
				handleSearchRoute  = { handleSearchRoute }
				recentSearches     = { recentSearches }
				trendingResults    = { trendingResults }

			/>
		</div>
	);
};

export default component;
