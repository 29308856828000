import { useAtom } from "jotai";
import { memo }    from "react";

import { useGetProfileId }        from "_common/hooks/auth";
import Toast                      from "_common/toast";
import { recentSearchesAtom }     from "_jotai/search.js";
import { clearRecentSearchesApi } from "_services/api/search";

import Presentation from "./presentation";

const component = props => {
	const [ , setRecentSearches ] = useAtom ( recentSearchesAtom );
	const userProfileId           = useGetProfileId ();

	const clearRecentSearches = async () => {
		try {
			await clearRecentSearchesApi ( userProfileId );

			setRecentSearches ( [] );
		} catch ( e ) {
			Toast.error ( "Cannot clear recent searches" );
		}

	};

	return (
		<Presentation
			{ ...props }
			clearRecentSearches = { clearRecentSearches }
		/>
	);
};

export default memo ( component );
