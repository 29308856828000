import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {

	return {
		predictiveSearchContainer: {
			padding : "2rem 0",
			width   : "100%"
		},
		searchContainer: {
			display        : "flex",
			padding        : "0.5rem 2rem",
			justifyContent : "space-between",
			"&:hover"      : {
				backgroundColor: theme.other.colors.background2.primary
			}
		},
		searchTermContainer: {
			display : "flex",
			width   : "70%"
		},
		searchIcon: {
			width       : "15px",
			height      : "auto",
			marginRight : "1rem"
		},
		textContainer: {
			color        : theme.other.colors.text.primary,
			cursor       : "pointer",
			width        : "100%",
			overflow     : "hidden",
			textOverflow : "ellipsis",
			whiteSpace   : "nowrap",
			fontSize     : "1.4rem"
		},
		searchedText: {
			color          : theme.other.paletteNew.secondaryDarkGrey,
			width          : "30%",
			display        : "flex",
			justifyContent : "flex-end",
			fontSize       : "1rem"
		}
	};
} );
