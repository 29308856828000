import { useAtom }   from "jotai";
import { useRouter } from "next/router";
import { useState }  from "react";

import { searchTermAtom } from "_jotai/search";

import Presentation from "./presentation";

const SearchInput = ( { onClose } ) => {
	const router = useRouter ();

	const [ searchTerm, setSearchTerm ]       = useAtom ( searchTermAtom );
	const [ popoverOpened, setPopoverOpened ] = useState ( false );

	const openPopover  = () => {
		setPopoverOpened ( true );
	};
	const closePopover = () => {
		setPopoverOpened ( false );
	};

	return (
		<Presentation
			closePopover  = { closePopover }
			onClose       = { onClose }
			openPopover   = { openPopover }
			popoverOpened = { popoverOpened }
			router        = { router }
			searchTerm    = { searchTerm }
			setSearchTerm = { setSearchTerm }
		/>
	);
};

export default SearchInput;