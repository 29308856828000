import Presentation from "./presentation";

const SearchModal = ( { toggleSearchModal, showSearchModal } ) => {

	return (
		<Presentation
			showSearchModal   = { showSearchModal }
			toggleSearchModal = { toggleSearchModal }
		/>

	);
};

export default SearchModal;