import { useDebouncedValue }   from "@mantine/hooks";
import { useAtom }             from "jotai";
import { useRouter }           from "next/router";
import { useEffect, useState } from "react";

import { storeEvent }                                                 from "_analytics/index";
import { useGetProfileData }                                          from "_common/hooks/auth";
import toast                                                          from "_common/toast";
import { recentSearchesAtom, trendingResultsAtom }                    from "_jotai/search.js";
import { getPredictiveSearches, getRecentSearchesAndTrendingQueries } from "_services/api/search";

import Presentation from "./presentation";

const component = ( { searchTerm, setSearchTerm, closePopover } ) => {

	const router = useRouter ();

	const [ predictiveSearches, setPredictiveSearches ] = useState ( [] );
	const [ recentSearches, setRecentSearches ]         = useAtom ( recentSearchesAtom );
	const [ trendingResults, setTrendingResults ]       = useAtom ( trendingResultsAtom );

	const userProfile             = useGetProfileData ();
	const [ debouncedSearchTerm ] = useDebouncedValue ( searchTerm, 200 );

	const [ isSearching, setIsSearching ] = useState ( false );

	const handleStart    = () => {
		setIsSearching ( true );
	};
	const handleComplete = () => {
		setIsSearching ( false );
	};

	// to check when page transition start and end
	useEffect ( () => {
		router.events.on ( "routeChangeStart", handleStart );
		router.events.on ( "routeChangeComplete", handleComplete );

		return () => {
		  router.events.off ( "routeChangeStart", handleStart );
		  router.events.off ( "routeChangeComplete", handleComplete );
		};
	}, [] );

	// Api call to fetch initial search recommendations when search input is focussed
	useEffect ( () => {
		fetchSearchData ();
	}, [] );

	const fetchSearchData = async () => {
		try {
			const data = await getRecentSearchesAndTrendingQueries ( userProfile?.id );

			setTrendingResults ( data?.trendingQueries );
			setRecentSearches ( data?.recentSearches );
		} catch ( e ) {
			toast.error ( "Cannot fetch results. Please reload the page." );
		}

	};

	useEffect ( () => {
		if ( searchTerm?.length < 3 ) return setPredictiveSearches ( [] );
	}, [ searchTerm ] );

	// Api call to fetch predective search suggestions on basis of debounced search term
	useEffect ( () => {
		if ( !debouncedSearchTerm || debouncedSearchTerm?.length < 3 ) return;
		getPredictiveResults ( debouncedSearchTerm );
	}, [ debouncedSearchTerm ] );

	const getPredictiveResults = async debouncedSearchTerm => {
		try {
			const data = await getPredictiveSearches ( debouncedSearchTerm );

			if ( data?.suggestions.length > 0 ) {
				setPredictiveSearches ( data?.suggestions );
			}
		} catch ( e ) {
			toast.error ( e.displayMsg );
		}

	};

	const handleGAEvent = ( query, analyticsLabel ) => {
		if ( !analyticsLabel )
			return storeEvent ( "Search_performed", query );

		return storeEvent ( "Search_performed", analyticsLabel, query );

	};

	const handleSearchRoute = ( query, analyticsLabel ) => {
		handleGAEvent ( query, analyticsLabel );
		setSearchTerm ( query );
		router.push ( `/results/${ encodeURIComponent ( query ) }?tab=all` );
		closePopover ();
	};

	return (
		<Presentation
			handleSearchRoute  = { handleSearchRoute }
			isSearching        = { isSearching }
			predictiveSearches = { predictiveSearches }
			recentSearches     = { recentSearches }
			searchTerm         = { searchTerm }
			trendingResults    = { trendingResults }
		/>
	);
};

export default component;
