import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => ( {
	headerChild: {
		display        : "flex",
		justifyContent : "center",
		position       : "absolute",
		left           : "50vw",
		transform      : "translate(-50%,0)"
	},
	searchBarContainer: {
		color           : theme.other.colors.text.primary,
		backgroundColor : theme.other.darkMode ?
			theme.other.paletteNew.darkModeDarkGrey :
			theme.other.paletteNew.secondaryBackgroundGrey,
		borderRadius: "10px"
	},
	popoverDropdown: {
		paddingInline     : "2rem",
		paddingBlockStart : "2rem",
		maxHeight         : "50vh",
		minHeight         : "20vh"
	},
	input: {
		fontSize: "1.4rem"
	},
	cssFocused: {
		borderColor: "#126bd5 !important"
	},
	popOver: {
		backgroundColor: theme.other.darkMode ?
			theme.other.paletteNew.darkModeDarkGrey :
			theme.other.paletteNew.primaryBackgroundGrey,

		height       : "fit-content",
		borderRadius : "20px",
		zIndex       : 100,
		overflowY    : "scroll",
		boxShadow    : "0 0 40px 10px rgba(0, 0, 0, 0.1)",
		padding      : ( props?.searchTerm?.length > 2 ) ? "0" : "2rem",
		display      : "block"
	},
	svgSearch: {
		width       : "2rem",
		height      : "2rem",
		marginRight : "1rem"
	},

	mobileSearchContainer: {
		display        : "flex",
		justifyContent : "center",
		padding        : "1.6rem",
		background     : theme.other.colors.background.tertiary,
		alignItems     : "center"
	},

	backIcon: {
		height      : "2rem",
		width       : "2rem",
		marginRight : "1rem"
	},

	crossIcon: {
		height : "2.6rem",
		width  : "2.6rem"
	},
	// mobile css
	mobileSearchModal: {
		 background : theme.other.colors.background.secondary,
		 height     : "100%"
		 }
} ) );
