import { useGetDeviceType } from "_common/hooks/global";
import Icon                 from "_components/atoms/icon";
import Loader               from "_components/atoms/loader";
import Render               from "_components/atoms/render/index";
import Typography           from "_components/atoms/typography";

import Ads       from "./ads";
import useStyles from "./styles";

const component = ( { trendingResults, recentSearches, handleSearchRoute, clearRecentSearches } ) => {
	const { classes } = useStyles ();
	const deviceType  = useGetDeviceType ();
	const isMobile    = deviceType === "mobile";

	if ( !trendingResults.length && !recentSearches.length ) return (
		<Loader
			noMargin
			style = { { height: "100%" } }
		/>
	);

	return (
		<div className = { classes.popoverWrapper }>
			<RecentSearches
				clearRecentSearches = { clearRecentSearches }
				handleRoute         = { handleSearchRoute }
				recentSearches      = { recentSearches }
			/>

			<TrendingResults
				handleRoute     = { handleSearchRoute }
				trendingResults = { trendingResults }
			/>

			<Render condition = { isMobile }>
				<Ads />
			</Render>
		</div>

	);
};

export default component;

const TrendingResults = ( { trendingResults, handleRoute } ) => {

	const { classes } = useStyles ();

	if ( !trendingResults || !trendingResults?.length ) return null;

	return (
		<div className = { classes.trendingResultsContainer }>

			<Typography
				className = { classes.headerContainer }
				title     = { "Trending Searches" }
			/>

			<div className = { classes.mapContainer }>
				{trendingResults?.map ( ( res, id ) => {
					return (
						<div
							key       = { id }
							className = { `${ classes.button } ${ classes.trendingPadding }` }
							onClick   = { () => handleRoute ( res?.term, "trending search" ) }
						>
							<Icon
								className = { classes.trendingIcon }
								name      = "trending"

							/>

							<Typography
								className = { classes.resultText }
								title     = { res?.term }
							/>

						</div>
					);
				} )}
			</div>

		</div>
	);
};

const RecentSearches = ( { recentSearches, handleRoute, clearRecentSearches } ) => {
	const { classes } = useStyles ();

	if ( !recentSearches || !recentSearches?.length ) return null;

	return (
		<div className = { classes.trendingResultsContainer }>

			<div className = { classes.headerContainer }>
				<Typography
					className = { classes.headerText }
					title     = { "Recent Searches" }
				/>

				<Typography
					className = { classes.clearText }
					onClick   = { clearRecentSearches }
					title     = { "Clear" }
				/>

			</div>

			<div className = { classes.mapContainer }>
				{recentSearches?.map ( ( res, id ) => {
					return (
						<div
							key       = { id }
							className = { `${ classes.button } ${ classes.trendingPadding }` }
							onClick   = { () => handleRoute ( res?.term ) }
						>
							<Icon
								className = { classes.trendingIcon }
								name      = "watch"

							/>

							<Typography
								className = { classes.resultText }
								title     = { res?.term }
							/>

						</div>
					);
				} )}
			</div>

		</div>

	);
};
