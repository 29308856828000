import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {

	drawerBody: {
		height: "100%"
		// padding : "unset"
	},

	searchModal: {
		background : theme.other.colors.background.primary,
		height     : "100%"
	},

	drawerRoot: {
		zIndex   : "10000",
		position : "absolute"
	}

} ), { name: "mobile-header-modal" } );
