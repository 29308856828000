import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => {

	return {
		popoverWrapper: {
			display       : "flex",
			flexDirection : "column",
			gap           : "2rem",
			padding       : "2rem"

		},
		trendingResultsContainer: {
			display       : "flex",
			flexDirection : "column"
		},
		headerContainer: {
			display        : "flex",
			justifyContent : "space-between",
			alignItems     : "center",
			marginBottom   : "1rem",
			fontWeight     : "500",
			fontSize       : "1.6rem"
		},
		headerText: {
			fontWeight : "500",
			fontSize   : "1.6rem"
		},
		clearText: {
			cursor   : "pointer",
			fontSize : "1.4rem",
			color    : theme.other.paletteNew.secondaryDarkGrey
		},
		mapContainer: {
			display    : "flex",
			flexWrap   : "wrap",
			alignItems : "center",
			gap        : "1rem"
		},
		button: {
			display         : "flex",
			alignItems      : "center",
			borderRadius    : "10px",
			cursor          : "pointer",
			backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey20 : theme.other.paletteNew.secondaryLightGrey,
			gap             : "5px",
			padding         : "0.5rem 1rem",
			border          : `1px solid ${ theme.other.darkMode ? theme.other.paletteNew.darkModeWhite20 : theme.other.paletteNew.secondaryLightGrey }`,

			[rooterMediaQuery.mobile]: {
				padding: "1rem 1.4rem"
			},
			[rooterMediaQuery.tablet]: {
				padding: "0.75rem 1.25rem"
			}
		},
		trendingPadding: {
			padding: "0.6rem 1.4rem",

			[rooterMediaQuery.tablet]: {
				padding: "0.5rem 1.25rem"
			}
		},
		trendingIcon: {
			width  : "15px",
			height : "15px"
		},
		test: {
			display    : "block",
			lineHeight : "0",
			fontSize   : "1.4rem"
		},

		resultText: {
			fontSize: "1.4rem"
		}
	};
} );
