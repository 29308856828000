import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => {

	return {
		initialResultsWrapper: {
			// height: "100%",

			[rooterMediaQuery.tablet]: {
				padding: "2rem 3rem"
			},

			[rooterMediaQuery.mobile]: {
				padding: "1rem"
			}

		}
	};
} );
