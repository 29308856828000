import dynamic from "next/dynamic";

import { useGetAdConfig } from "_common/hooks/global";
import { useGetDarkMode } from "_common/hooks/global";

const AdSlot        = dynamic ( () => import ( "_components/features/ads/slot/single" ) );
const Advertisement = dynamic ( () => import ( "_components/features/ads/bannerMobile" ) );

const component = () => {
	const { SEARCH_BOTTOM_MWEB: config } = useGetAdConfig ();
	const darkMode                       = useGetDarkMode ();

	if ( !config?.enabled )
		return null;

	if ( config?.adManager === "vmax" )
		return (
			<Advertisement id = { config?.adUnitId } />
		);

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	return (
		<AdSlot
			id    = { "gpt-passback" }
			index = { "search" }
			path  = { path }
			size  = { config?.size }
		/>
	);
};

export default component;
