import { atom } from "jotai";

export const searchTermAtom = atom ( "" );
export const recentSearchesAtom = atom ( [] );
export const trendingResultsAtom = atom ( [] );

export const updateSearchTerm = atom (
	null,
	( get, set, update ) => {
		set ( searchTermAtom, () => update );
	}
);
