import { useAtom } from "jotai";

import { searchTermAtom } from "_jotai/search";

import Presentation from "./presentation";

const SearchTextField = ( { onFocus, onPressEnter, onPressEsc, placeholder = "Search", inputRef } ) => {
	const [ searchTerm, setSearchTerm ] = useAtom ( searchTermAtom );

	return (
		<Presentation
			inputRef      = { inputRef }
			onFocus       = { onFocus }
			onPressEnter  = { onPressEnter }
			onPressEsc    = { onPressEsc }
			placeholder   = { placeholder }
			searchTerm    = { searchTerm }
			setSearchTerm = { setSearchTerm }
		/>
	);
};

export default SearchTextField;